import React from "react";
import ButtonLink from "../components/ButtonLink";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

const About = () => {
    return (
        <Layout>
            <Seo />

            <div className="grid__main">
                <div className="grid__left">
                    <h1 className="h1">
                        I’m <strong className="text__name">Arek Wtykło</strong>,
                        <br />
                        nice to meet you!
                    </h1>
                    <p className="text__lead">
                        I've been focused on web technologies for over 12 years. Working in smart industries, computer
                        networks, gamification and e-commerce. Sharing knowledge as a trainer for over 5 years.
                    </p>
                    <p className="text__lead">Currently working with React-based applications and Symfony (PHP).</p>

                    <br />
                    <br />
                    <br />

                    <p className="buttonLink__text">
                        You can find more information about me, my experience and projects below.
                    </p>

                    <div className="buttonLink__wrapper">
                        <ButtonLink {...{ title: "Projects", to: "/projects" }} />
                        <ButtonLink {...{ title: "About me", to: "/about" }} />
                        <ButtonLink {...{ title: "Contact", to: "/contact" }} />
                    </div>
                </div>
                <div className="grid__right">
                    <div className="coffee__wrapper">
                        <div className="coffee__text">
                            Here’s a <strong>fresh coffee</strong> for you to have a pleasant read.
                        </div>
                        <div className="coffee__main">
                            <div className="coffee__cup" />
                            <div className="coffee__coffee" />
                            <div className="coffee__handle -outer" />
                            <div className="coffee__handle -inner" />
                            <div className="coffee__smoke">
                                {[...Array(20)].map(() => (
                                    <div className="coffee__smokeParticle" />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default About;
